import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Button } from "../components/Button";
import { Image } from "../components/Image";
import { ChevronRightIcon } from "@heroicons/react/solid";

const HelpPage = () => (
  <>
    <Header />
    <div className="bg-white">
      <div className="relative mt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:mt-32 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-4xl font-semibold tracking-tight text-black sm:text-5xl lg:text-6xl">
          Help Desk
        </h1>
        <p className="mt-6 text-xl text-cyan-100 max-w-3xl">
          Please contact{" "}
          <a
            className="text-navy-600 hover:text-navy-700 hover:underline"
            href="mailto:research@cbtn.org"
          >
            research@cbtn.org
          </a>{" "}
          or{" "}
          <a
            className="text-navy-600 hover:text-navy-700 hover:underline"
            href="mailto:support@kidsfirstdrc.org"
          >
            support@kidsfirstdrc.org
          </a>{" "}
          with any questions about accessing datasets and using platforms
        </p>
        <div className="mt-6 text-xl text-cyan-100 max-w-3xl space-x-4">
          <Button
            as="a"
            href="/frequently-asked-questions"
            variant="subtle"
            size="4"
          >
            Frequently Asked Questions
          </Button>
          <Button as="a" href="/research-resources" variant="subtle" size="4">
            Research Resources
          </Button>
        </div>
      </div>
    </div>
    <div className="bg-white">
      <div className="relative pt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-8 mb-24">
          <div className="md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
            <h2 className="block text-2xl text-gray-800 tracking-tight sm:text-3xl">
              CBTN Early Career Investigator Info Session
            </h2>
            <p className="mt-3 text-base text-gray-700">
              On September 28, 2021, CBTN presented an open house session geared
              toward early-career investigators and those interested in
              launching new research projects.
            </p>
            <p className="mt-3 text-base text-gray-700">Featuring:</p>
            <p className="mt-3 text-base text-gray-700">
              <ul role="list" className="space-y-2">
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-base">
                    An overview of the biospecimen resources, data, analysis
                    tools, and expert support available to researchers and
                    clinicians practicing anywhere on earth.
                  </p>{" "}
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-base">
                    How to access these resources at no charge, and get guidance
                    on how to utilize them to power your research.
                  </p>{" "}
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-base">
                    Case studies from current investigators and learn how CBTN
                    has helped them make progress in the lab and in the clinic.
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="flex-shrink-0">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-base">
                    Opportunities to network with professional and academic
                    peers.
                  </p>{" "}
                </li>
                <li className="flex items-center">
                  {" "}
                  <div className="flex-shrink-0">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-4 w-4 text-gray-500"
                      aria-hidden="true"
                    />
                  </div>
                  <p className="ml-3 text-base">
                    Q&As with a multidisciplinary panel of experts.
                  </p>
                </li>
              </ul>
            </p>
          </div>
          <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
            <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
              <div className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span className="sr-only">Watch our video to learn more</span>
                <LiteYouTubeEmbed
                  id="KTL6pemGQug"
                  title="CBTN Early Career Investigator Info Session - Sept 2021 "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid gap-12 lg:grid-cols-3">
          <div>
            <Image
              src="https://cbtn-s3.imgix.net/Kids_First_Graphic_Horizontal_OL_FINAL.DRC-01.png"
              height={50}
            />
            <div className="mt-12">
              <h3 className="text-xl font-medium">
                Kids First Data Resource Center
              </h3>
              <div className="mt-8 space-y-4">
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.notion.so/d3b/Kids-First-DRC-Help-Center-c26b36ff66564417834f3f264475d10a"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  Kids First DRC Help Center
                </a>
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://training.nih-cfde.org/en/latest/Common-Fund-Tools/Kids-First/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                    />
                  </svg>
                  CFDE Tutorials & Lessons
                </a>
              </div>
            </div>
          </div>
          <div>
            <Image
              src="https://cbtn-s3.imgix.net/Cavativa-Logo-742x158.png"
              height={50}
            />
            <div className="mt-12">
              <h3 className="text-xl font-medium">Cavatica</h3>
              <div className="mt-8 space-y-4">
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://docs.cavatica.org/docs"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  CAVATICA Support Documents
                </a>
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://docs.sevenbridges.com/page/api"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  Seven Bridges API Documentation
                </a>
              </div>
            </div>
          </div>
          <div>
            <Image
              src="https://cbtn-s3.imgix.net/cbioportal_logo.png"
              height={50}
            />
            <div className="mt-12">
              <h3 className="text-xl font-medium">PedCBio Portal</h3>
              <div className="mt-8 space-y-4">
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://pedcbioportal.kidsfirstdrc.org/tutorials"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                    />
                  </svg>
                  PedCBio Portal Tutorials & Training Webinars
                </a>
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://docs.google.com/presentation/d/1X4SnZqeUhE-8vwbiczy4uqORsNxbCg8Q-zi00PXJdkU/edit#slide=id.gd082725a11_0_0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  PedCBio Portal Training Deck
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="relative pt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:pt-32 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="grid gap-12 lg:grid-cols-2">
            <div>
              <h3 className="text-xl font-medium">Office Hours Sessions</h3>
              <div className="mt-8 space-y-4">
                <p>2PM ET on the 3rd Thursday of each month</p>
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  href="https://calendar.google.com/calendar/ical/c_76c1b063992a9e351d823450ef5ba38200756e1b2b6941ebead4f22d8f2568b5%40group.calendar.google.com/public/basic.ics"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                    />
                  </svg>
                  Add session to your calendar
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-medium">Contact</h3>
              <div className="mt-8 space-y-4">
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  href="mailto:research@cbtn.org"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  research@cbtn.org
                </a>
                <a
                  className="group flex items-center text-navy-600 hover:text-navy-700 hover:underline"
                  href="mailto:support@kidsfirstdrc.org"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    height="24"
                    className="mr-2 text-navy-300 group-hover:text-navy-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  support@kidsfirstdrc.org
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="mt-24" />
    <Footer />
  </>
);

export default HelpPage;
